/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

export function formatToDateTime(
  date: dayjs.Dayjs | undefined = undefined,
  format = DATE_TIME_FORMAT,
): string {
  return dayjs(date).format(format);
}

export function formatToDate(
  date: dayjs.Dayjs | undefined = undefined,
  format = DATE_FORMAT,
): string {
  return dayjs(date).format(format);
}

export function isSameDay(date: dayjs.Dayjs | undefined = undefined): boolean {
  return dayjs().isSame(date, 'day');
}

export function isBeforeDay(date: dayjs.Dayjs | undefined = undefined): boolean {
  return dayjs().isBefore(date, 'day');
}

export function isAfterDay(date: dayjs.Dayjs | undefined = undefined): boolean {
  return dayjs().isAfter(date, 'day');
}

export function isAfterSameDay(date: dayjs.Dayjs | undefined = undefined): boolean {
  return isSameDay(date) || isAfterDay(date);
}

export function isBetweenDay(
  start: dayjs.Dayjs | undefined = undefined,
  end: dayjs.Dayjs | undefined = undefined,
): boolean {
  return dayjs().isBetween(start, end, 'day');
}

export function isBetweenStartSameDay(
  start: dayjs.Dayjs | undefined = undefined,
  end: dayjs.Dayjs | undefined = undefined,
): boolean {
  return isSameDay(start) || isBetweenDay(start, end);
}

export function isBetweenSameDay(
  start: dayjs.Dayjs | undefined = undefined,
  end: dayjs.Dayjs | undefined = undefined,
): boolean {
  return isSameDay(start) || isBetweenDay(start, end) || isSameDay(end);
}

export const dateUtil = dayjs;
