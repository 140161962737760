import { defHttp } from '/@/utils/http/axios';
import { LoginParams, LoginResultModel } from './model/userModel';
import { stringify } from 'qs';
import { ErrorMessageMode } from '/#/axios';
import { UserInfo } from '/#/store';

enum Api {
  // Login = '/j_login',
  Login = '/userlogin/check',
  GetAuthList = '/api/authentication/find-by-user',
  GetUserInfo = '/api/screen/find-by-user',
  GetPermCode = '/getPermCode',
  findAllUser = '/api/user/findUserList',
}
export function findAllUser() {
  return defHttp.get<UserInfo[]>({ url: Api.findAllUser }, { errorMessageMode: 'message' });
}
/**
 * @description: 用户登录
 */
export function loginApi(params: LoginParams, mode: ErrorMessageMode = 'modal') {
  return defHttp.post<LoginResultModel>(
    {
      url: Api.Login + '?' + stringify(params),
      params,
    },
    {
      errorMessageMode: mode,
    },
  );
}

/**
 * @description: 获取路由信息
 */
export function getUserInfo() {
  return defHttp.get<UserInfo[]>({ url: Api.GetUserInfo }, { errorMessageMode: 'message' });
}

/**
 * @description: 获取用户权限信息
 */
export function getAuthList(params: { userId: number }) {
  return defHttp.get<string[]>({ url: Api.GetAuthList, params }, { errorMessageMode: 'message' });
}

export function getPermCode() {
  return defHttp.get<string[]>({ url: Api.GetPermCode });
}
