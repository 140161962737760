import type { UserData, UserInfo } from '/#/store';
import type { ErrorMessageMode } from '/#/axios';
import { defineStore } from 'pinia';
import { store } from '/@/store';
import { PageEnum } from '/@/enums/pageEnum';
import { AUTH_KEY, TOKEN_KEY, USER_DATA_KEY, USER_INFO_KEY } from '/@/enums/cacheEnum';
import { getAuthCache, setAuthCache } from '/@/utils/auth';
import { LoginParams, LoginResultModel } from '/@/api/sys/model/userModel';
import { getAuthList, getUserInfo, loginApi } from '/@/api/sys/user';
import { useI18n } from '/@/hooks/web/useI18n';
import { useMessage } from '/@/hooks/web/useMessage';
import { router } from '/@/router';
import { usePermissionStore } from '/@/store/modules/permission';
import { RouteRecordRaw } from 'vue-router';
import { PAGE_NOT_FOUND_ROUTE } from '/@/router/routes/basic';
import { h } from 'vue';

interface UserState {
  userInfo: Nullable<UserInfo[]>;
  userdata: Nullable<UserData>;
  token?: string;
  authList: string[];
  sessionTimeout?: boolean;
  lastUpdateTime: number;
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): UserState => ({
    // 用户信息
    userInfo: null,
    //用户数据
    userdata: null,
    // token
    token: undefined,
    // 角色
    authList: [],
    // 登录是否已过期
    sessionTimeout: false,
    // 最后提取时间
    lastUpdateTime: 0,
  }),
  getters: {
    getUserInfo(): UserInfo[] {
      return this.userInfo || getAuthCache<UserInfo[]>(USER_INFO_KEY) || {};
    },
    getUserData(): UserData {
      return this.userdata || getAuthCache<UserData>(USER_DATA_KEY) || {};
    },
    getToken(): string {
      return this.token || getAuthCache<string>(TOKEN_KEY);
    },
    getAuthList(): string[] {
      return this.authList.length > 0 ? this.authList : getAuthCache<string[]>(AUTH_KEY);
    },
    getSessionTimeout(): boolean {
      return !!this.sessionTimeout;
    },
    getLastUpdateTime(): number {
      return this.lastUpdateTime;
    },
    getIsSale(): boolean {
      const user = this.userdata || getAuthCache<UserData>(USER_DATA_KEY) || {};
      // console.log('>>>>>>用户类型', user?.userType);
      return 'B' === user?.userType;
    },
  },
  actions: {
    setToken(info: string | undefined) {
      this.token = info ? info : ''; // for null or undefined value
      setAuthCache(TOKEN_KEY, info);
    },
    setAuthList(authList: string[]) {
      this.authList = authList;
      setAuthCache(AUTH_KEY, authList);
    },
    setUserInfo(info: UserInfo[] | null) {
      this.userInfo = info;
      this.lastUpdateTime = new Date().getTime();
      setAuthCache(USER_INFO_KEY, info);
    },
    setUserData(data: UserData | null) {
      this.userdata = data;
      this.lastUpdateTime = new Date().getTime();
      setAuthCache(USER_DATA_KEY, data);
    },
    setSessionTimeout(flag: boolean) {
      this.sessionTimeout = flag;
    },
    resetState() {
      this.userInfo = null;
      this.userdata = null;
      this.token = '';
      this.authList = [];
      this.sessionTimeout = false;
    },
    /**
     * @description: login
     */
    async login(
      params: LoginParams & {
        goHome?: boolean;
        mode?: ErrorMessageMode;
      },
    ): Promise<UserData | null> {
      try {
        const { goHome = true, mode, ...loginParams } = params;
        const data = await loginApi(loginParams, mode);

        // const { user_token } = data;
        const { satoken } = data;
        // save token
        // this.setToken(user_token);
        this.setToken(satoken);
        this.setUserData(data);

        return this.afterLoginAction(data, goHome);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async afterLoginAction(data?: LoginResultModel, goHome?: boolean): Promise<UserData | null> {
      if (!this.getToken) return null;
      // get user info
      const userInfo = await this.getUserInfoAction();

      if (data) {
        const authList = await getAuthList({ userId: data.userid });
        this.setAuthList(authList);
      }

      const sessionTimeout = this.sessionTimeout;
      if (sessionTimeout) {
        this.setSessionTimeout(false);
      } else {
        const permissionStore = usePermissionStore();
        if (!permissionStore.isDynamicAddedRoute) {
          const routes = await permissionStore.buildRoutesAction();
          console.log(routes);
          routes.forEach((route) => {
            router.addRoute(route as unknown as RouteRecordRaw);
          });
          router.addRoute(PAGE_NOT_FOUND_ROUTE as unknown as RouteRecordRaw);
          permissionStore.setDynamicAddedRoute(true);
        }
        goHome && (await router.replace(PageEnum.BASE_HOME));
      }
      return Object.assign({ router: userInfo }, this.userdata);
    },
    async getUserInfoAction(): Promise<UserInfo[] | null> {
      if (!this.getToken) return null;
      const userInfo = await getUserInfo();

      this.setUserInfo(userInfo);
      return userInfo;
    },
    /**
     * @description: logout
     */
    async logout(goLogin = false) {
      this.setToken(undefined);
      this.setSessionTimeout(false);
      this.setUserInfo(null);
      this.setUserData(null);
      this.setAuthList([]);
      goLogin && (await router.push(PageEnum.BASE_LOGIN));
    },

    /**
     * @description: Confirm before logging out
     */
    confirmLoginOut() {
      const { createConfirm } = useMessage();
      const { t } = useI18n();
      createConfirm({
        iconType: 'warning',
        title: () => h('span', t('sys.app.logoutTip')),
        content: () => h('span', t('sys.app.logoutMessage')),
        onOk: async () => {
          await this.logout(true);
        },
      });
    },
  },
});

// 需要在设置之外使用
export function useUserStoreWithOut() {
  return useUserStore(store);
}
